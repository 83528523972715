import React, { lazy, Suspense } from "react";

import { Box } from "@mui/material";
import { DndProvider } from "react-dnd";
import { client } from "./config/apollo";

import { ApolloProvider } from "@apollo/client";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/loading";

const Error = lazy(() => import("./pages/404"));

const ReferralManage = lazy(() => import("./pages/referralManage"));
const FormRuleReferral = lazy(() => import("./pages/form/formReferral"));

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <Box p={3}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="*" element={<Error />} />

                {/* CONTENT MANAGE */}
                <Route
                  path="/referral"
                  element={<ReferralManage />}
                />
                <Route
                  path="/referral/form"
                  element={<FormRuleReferral />}
                />
                <Route
                  path="/referral/form/:id"
                  element={<FormRuleReferral />}
                />


              </Routes>
            </Suspense>
          </BrowserRouter>
        </DndProvider>
      </Box>
    </ApolloProvider>
  );
}
